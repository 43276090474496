import Vue from 'vue';
import { sentryInit } from '@/sentry.js';
import spark from '@spark/spark.js';
import '@spark/spark-bootstrap.js';

import AccountRegistrationForm from '@/components/form/AccountRegistrationForm.vue';
import ResetPasswordModal from '@/components/admin/modals/ResetPasswordModal.vue';
import ResendConsumerReportModal from '@/components/report/modals/ResendConsumerReportModal.vue';
import SimpleAlerts from '@/components/layouts/SimpleAlerts.vue';

import '@/spark-components/bootstrap.js';

if (!['local', 'testing'].includes(import.meta.env.VITE_SENTRY_ENVIRONMENT)) {
    sentryInit();
}

import '@/shared/filters.js';

Vue.component('AccountRegistrationForm', AccountRegistrationForm);
Vue.component('ResetPasswordModal', ResetPasswordModal);
Vue.component('ResendConsumerReportModal', ResendConsumerReportModal);
Vue.component('SimpleAlerts', SimpleAlerts);

window.app = new Vue({
    mixins: [spark],
});
